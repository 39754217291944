<script lang="ts" setup>
import Tooltip from '@/components/common/Tooltip.vue';

interface Props {
  inverted?: boolean;
  className?: string;
}

const config = useRuntimeConfig();

withDefaults(defineProps<Props>(), {
  inverted: false
});

const glossaryUrl = computed(() => `${config.public.bridge128Url}/glossary`);
</script>

<template>
  <Tooltip content="Glossary">
    <NuxtLink
      :to="glossaryUrl"
      target="_blank"
      :class="[
        'flex items-center justify-center rounded-full min-w-11 min-h-11',
        inverted
          ? 'bg-brand-navy-500 hover:bg-brand-navy-600'
          : 'bg-brand-white hover:bg-white',
        className
      ]"
    >
      <NuxtIcon
        name="book"
        filled
        :class="['book-icon flex justify-center', inverted && 'inverted']"
      />
    </NuxtLink>
  </Tooltip>
</template>
<style lang="scss" scoped>
:deep(.book-icon) {
  @apply text-brand-navy-600;

  svg {
    @apply w-6 h-6 mb-0;
  }

  &.inverted {
    @apply text-white;
  }
}
</style>
